@import '@trimbleinc/modus-react-bootstrap/css/dist/modus-react-bootstrap.min.css';

.custom-flex-container {
  width: 500px;
}
.custom-accordion {
  width: 500px;
}

.box-container {
  max-width: 500px;
  margin: 0 auto;
  padding: 20px 20px 0px;
  background-color: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
  border-radius: 8px;
  color: #464b52;
  font-size: 15px;
}

.custom-scroll-intro {
  overflow-y: scroll;
  max-height: 640px;
}

.custom-scroll {
  overflow-y: scroll;
  max-height: 300px;
}
.custom-navbar {
  padding: 0.6rem 1rem;
}

.custom-allign {
  padding-right: 10px;
}

.custom-tree-label {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  font-size: 13px;
}

.hgwxHt .item-indent {
  padding-left: 2rem !important;
}

.custom-title-line-breaks {
  margin-top: -40px;
  margin-bottom: 5px;
}

.custom-component-name {
  color: #464b52;
  font-size: 15px;
  margin: 0px 0px 7px;
}

.enable-tooltip {
  display: block;
}

.disable-tooltip {
  display: none;
}

.custom-card-bg {
  padding: 10px;
}

.ter-navbar-top__logo {
  height: 25px;
  margin: 100px 8px 0 0 !important;
  max-width: 289px;
  width: auto;
}

.ter-navbar-top__division-name {
  color: #0063a3;
  display: block;
  font-size: 18px;
  font-weight: 350 !important;
  margin: 100px 0 0;
}

.cursor {
  cursor: pointer;
  margin-right: 2px;
  margin-left: 2px;
}

.carousel-control-next,
.carousel-control-prev,
.carousel-indicators {
  filter: invert(100%);
}

.carousel-control-prev {
  margin-left: -7.5%;
}
.carousel-control-next {
  margin-right: -7.5%;
}

.carousal-help-text {
  font-family: 'Open Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI',
    Roboto, 'Helvetica Neue', Arial, 'Noto Sans', sans-serif,
    'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';
  padding: 10px;
  font-size: 16px;
}

.access-page{
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color:#004f83;
    width: 100%;
    height: 100%;
    margin-top: 300px;
}

@media (max-width: 768px) {
  .custom-flex-container {
    width: 100%;
  }
  .custom-accordion {
    width: 100%;
  }

  .custom-title-line-breaks {
    margin-top: -40px;
    margin-bottom: 5px;
  }
  .box-container {
    margin: 0 auto;
    padding: 20px 20px 0px;
    background-color: white;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.5);
    border-radius: 8px;
  }
  .custom-scroll-intro {
    overflow-y: scroll;
    max-height: 285px;
  }
}
